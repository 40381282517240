import Member from '@/models/Member';
import Player from '@/models/Player';

/**
 * 対戦データのモデルです。
 */
export default class TournamentModel {
    /**
     * トーナメント名を取得/設定します。
     */
    public TournamentName = '';

    /**
     * トーナメントタイプを取得/設定します。
     */
    public TournamentType = -1;

    /**
     * 参加者の名前一覧を取得/設定します。
     */
    public NameList = new Array<Member>();

    /**
     * ソート済みの参加者リストを取得/設定します。
     */
    public SortedNameList = new Array<Member>();

    /**
     * 対戦リストを取得/設定します。
     */
    public MatchList = new Array<Array<Player>>();

    /**
     * ポイント設定を取得/設定します。
     * 0:未設定 1:勝ち 2:負け 3:CO
     */
    public PointSetting = [0, 2, 1, 0];

    /**
     * レーン数を取得/設定します。
     */
    public Lane = 3;
}